import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding, Inject,
  OnDestroy,
  OnInit, PLATFORM_ID,
  Renderer2
} from '@angular/core';
import { LayoutService, MenuItem } from '../layout.service';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { animate, style, transition, trigger } from '@angular/animations';
import { DialogService, isTouchDevice } from '@mtgame/ui';
import { AgreementComponent } from '../../agreement/agreement.component';
import { LoginComponent, AuthService } from '@mtgame/auth';
import { User, componentDestroyed, UntilDestroy, League } from '@mtgame/core';
import { getLeagueRoute, getProfileFormRoute, getProfileRoute, getProfileSettingsRoute } from '@config/routes';
import { HeaderService } from '../header.service';
import { LeagueRootService } from '@core/services/league-root.service';
import { FeedbackComponent } from '../../feedback/feedback.component';
import { isPlatformBrowser } from '@angular/common';

@UntilDestroy
@Component({
  selector: 'mtg-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideLeft', [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('0.3s ease', style({transform: 'translateX(0)'}))
      ]),
      transition(':leave', [
        style({transform: 'translateX(0)'}),
        animate('0.3s ease', style({transform: 'translateX(-100%)'}))
      ])
    ])
  ],
})
export class HeaderComponent implements OnInit, OnDestroy {
  isBrowser = isPlatformBrowser(this.platformId);
  logo: string;
  title: string;
  leagueMenu: MenuItem[] = [];
  tournamentMenu: MenuItem[] = [];
  mobileMenuVisible: boolean;
  mobileLeagueMenuVisible = false;
  user: User;
  userInitialized: boolean;
  initialized: boolean;
  visible: boolean;
  league: League;
  static = false;
  hideTimeout: any;
  mainRoute: string[];
  subscriptions: any[] = [];
  sticky: boolean;

  get mainMenu(): MenuItem[] {
    if (this.tournamentMenu.length === 0) {
      return this.leagueMenu;
    }
    return this.tournamentMenu;
  }

  get subMenu(): MenuItem[] {
    if (this.tournamentMenu.length > 0) {
      return this.leagueMenu;
    }
    return [];
  }

  get leagueRoute(): string[] {
    return getLeagueRoute();
  }

  @HostBinding('class.with-sub-menu')
  get withSubMenuClass() {
    return this.subMenu.length > 0;
  }

  @HostBinding('class.sticky')
  get stickyClass() {
    return this.sticky;
  }

  get isBeta(): boolean {
    return Boolean(this.isBrowser && localStorage.getItem('beta'));
  }

  constructor(
    private leagueRootService: LeagueRootService,
    private layoutService: LayoutService,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    private authService: AuthService,
    private headerService: HeaderService,
    private renderer2: Renderer2,
    private elementRef: ElementRef,
    @Inject(PLATFORM_ID) private platformId: string,
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.renderer2.listen('window', 'scroll', event => this.onScroll(event))
    );

    this.leagueRootService.league$
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(league => {
        this.league = league;
        this.cdr.markForCheck();
      });
    combineLatest([
      this.layoutService.logo$.pipe(takeUntil(componentDestroyed(this))),
      this.layoutService.title$.pipe(takeUntil(componentDestroyed(this))),
      this.layoutService.mainRoute$.pipe(takeUntil(componentDestroyed(this)))
    ])
      .subscribe(([logo, title, mainRoute]) => {
        this.logo = logo;
        this.title = title;
        this.mainRoute = mainRoute;
        this.initialized = true;
        this.cdr.markForCheck();
      });

    this.layoutService.leagueMenu$
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(menu => {
        this.leagueMenu = menu;
        this.cdr.markForCheck();
      });
    this.layoutService.tournamentMenu$
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(menu => {
        this.tournamentMenu = menu;
        this.cdr.markForCheck();
      });

    this.layoutService.user$.pipe(takeUntil(componentDestroyed(this)))
      .subscribe(user => {
        if (this.user !== user) {
          this.user = user;
          this.userInitialized = true;
          this.cdr.markForCheck();
        }
      });

    this.headerService.visible$
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe(visible => {
        clearTimeout(this.hideTimeout);
        this.visible = visible;
        if (!visible) {
          this.hideTimeout = setTimeout(() => {
            this.static = !visible;
            this.cdr.markForCheck();
          }, 400);
        } else {
          this.static = !visible;
          this.cdr.markForCheck();
        }
        this.cdr.markForCheck();
      });
  }

  ngOnDestroy(): void {
    for (const unsubscribe of this.subscriptions) {
      unsubscribe();
    }
  }

  onScroll(event: any): void {
    const box = this.elementRef.nativeElement.getBoundingClientRect();
    if (this.sticky && box.top > 0) {
      this.sticky = false;
      this.cdr.markForCheck();
    } else if (!this.sticky && box.top <= 0) {
      this.sticky = true;
      this.cdr.markForCheck();
    }
  }

  toggleMobileMenu(): void {
    if (this.mobileMenuVisible) {
      this.hideMenu();
    } else {
      this.showMenu();
    }
  }

  showMenu(): void {
    this.mobileMenuVisible = true;
    document.body.classList.add('no-scroll');
  }

  hideMenu(): void {
    this.mobileMenuVisible = false;
    document.body.classList.remove('no-scroll');
  }

  toggleLeagueMenu(): void {
    this.mobileLeagueMenuVisible = !this.mobileLeagueMenuVisible;
  }

  openFeedbackModal(): void {
    this.dialogService.open(FeedbackComponent, {
      isCloseButtonVisible: true
    });
    this.hideMenu();
  }

  openAgreement(): void {
    this.hideMenu();
    this.dialogService.open(AgreementComponent, {
      isCloseButtonVisible: true
    });
  }

  login() {
    this.dialogService.open(LoginComponent);
  }

  async logout(): Promise<void> {
    await this.authService.logout();
  }

  openProfile(): void {
    window.open(getProfileRoute().join('/'), '_blank');
  }

  openProfileForm(): void {
    window.open(getProfileFormRoute().join('/'), '_blank');
  }

  openSettings(): void {
    window.open(getProfileSettingsRoute().join('/'), '_blank');
  }
}
