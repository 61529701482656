import { Renderer2 } from '@angular/core';

export type TokenStyles = {
  colorPrimary?: string;
  colorPrimaryText?: string;
  colorSecondary?: string;
  colorSecondaryText?: string;
  colorCardBase?: string;
  colorCardFont?: string;
  colorCardFontMuted?: string;
  colorControlBase?: string;
  colorControl?: string;
  colorControlMuted?: string;
  colorTextField?: string;
  colorPanel?: string;
  colorPanelFont?: string;
  colorPanelFontMuted?: string;
  colorPanelHeader?: string;
  colorPanelHeaderText?: string;
  colorBackground?: string;
  colorControlBorder?: string;
  colorControlElement?: string;
  colorTextSecondary?: string;
  colorChipsPrimary?: string;
  colorChipsSecondary?: string;
  colorFont?: string;
  patternBg?: string;
  patternCard?: string;
  patternPanel?: string;
  patternControlBase?: string;
};

const LEAGUE_TOKENS: {[key: number]: TokenStyles} = {};
const BETA_TOKENS: {[key: number]: TokenStyles} = {};
// LEAGUE_TOKENS[12] = {
//   colorPrimary: '#E23746',
//   colorCardBase: '#1B1B25',
//   colorControlBase: '#242430',
//   colorPanel: '#1B1B25',
//   colorBackground: '#111219',
//   colorControlBorder: 'rgba(255, 255, 255, .2)',
//   colorControlElement: 'rgba(255, 255, 255, .2)',
// };
LEAGUE_TOKENS[19] = {
  colorPrimary: '#FFBB0D',
  colorPrimaryText: '#23208C',
  colorSecondary: '#FFBB0D',
  colorSecondaryText: '#23208C',
  colorCardBase: '#302DAA',
  colorControlBase: '#3849C2',
  colorPanel: '#3330b3',
  colorPanelHeader: '#3d3abd',
  colorBackground: '#272492',
  colorControlBorder: rgba('#A6A4EC', .2),
  colorControlElement: '#A6A4EC',
  colorTextSecondary: '#A6A4EC',
  colorChipsPrimary: adjustColor('#FFBB0D', -8),
  colorChipsSecondary: adjustColor('#3849C2', -8),
};
BETA_TOKENS[19] = {
  colorPrimary: '#f2c07c',
  colorPrimaryText: '#0e0e0e',
  colorSecondary: '#f2c07c',
  colorSecondaryText: '#1c304d',
  colorCardBase: '#ffffff',
  colorCardFont: '#0e0e0e',
  colorControlBase: '#1c304d',
  colorPanel: '#2b4770',
  colorPanelHeader: adjustColor('#2b4770', 8),
  colorBackground: '#1f3356',
  colorControlBorder: rgba('#6F85AB', .2),
  colorControlElement: '#6F85AB',
  colorTextSecondary: '#6F85AB',
  colorChipsPrimary: adjustColor('#f2c07c', -8),
  colorChipsSecondary: adjustColor('#1E345B', -8),
  patternBg: 'url(/assets/pattern.png) repeat',
};

LEAGUE_TOKENS[14] = {
  colorPrimary: '#C31D23',
  colorPrimaryText: '#FFFFFF',
  colorSecondary: '#C31D23',
  colorSecondaryText: '#FFFFFF',
  colorCardBase: '#181818',
  colorControlBase: '#242424',
  colorPanel: '#1d1d1d',
  colorPanelHeader: '#272727',
  colorBackground: '#070707',
  colorControlBorder: rgba('#737373', .2),
  colorControlElement: '#737373',
  colorTextSecondary: '#737373',
  colorChipsPrimary: adjustColor('#C31D23', -8),
  colorChipsSecondary: adjustColor('#242424', -8),
};
BETA_TOKENS[14] = {
  colorPrimary: '#c82b31',
  colorPrimaryText: '#FFFFFF',
  colorSecondary: '#C31D23',
  colorSecondaryText: '#FFFFFF',
  colorCardBase: '#ffffff',
  colorCardFont: '#111111',
  colorCardFontMuted: '#777777',
  colorControlBase: '#a46b2f',
  colorControl: '#ffffff',
  colorControlMuted: 'rgba(255, 255, 255, 0.5)',
  colorPanel: '#f1f1f1',
  colorPanelFont: '#111111',
  colorPanelFontMuted: '#777777',
  colorPanelHeader: '#f9f9f9',
  colorPanelHeaderText: '#111111',
  colorBackground: adjustColor('#a10c0c', -12),
  colorControlBorder: '#c1c1c1',
  colorControlElement: 'rgba(255, 255, 255, 0.5)',
  colorTextSecondary: '#c1c1c1',
  colorChipsPrimary: adjustColor('#c82b31', -8),
  colorChipsSecondary: '#777777',
  patternBg: 'url(/assets/pattern.png) repeat',
  // patternCard: 'url(/assets/pattern.png) repeat',
  patternPanel: 'url(/assets/pattern.png) repeat',
  patternControlBase: 'url(/assets/pattern.png) repeat',
};

LEAGUE_TOKENS[34] = {
  colorPrimary: '#C31D23',
  colorPrimaryText: '#FFFFFF',
  colorSecondary: '#C31D23',
  colorSecondaryText: '#FFFFFF',
  colorCardBase: '#001818',
  colorControlBase: '#001C1C',
  colorPanel: '#001313',
  colorPanelHeader: '#0a1d1d',
  colorBackground: '#002323',
  colorControlBorder: rgba('#617272', .2),
  colorControlElement: '#617272',
  colorTextSecondary: '#617272',
  colorChipsPrimary: adjustColor('#C31D23', -8),
  colorChipsSecondary: adjustColor('#001C1C', -8),
};
BETA_TOKENS[34] = {
  colorPrimary: '#C31D23',
  colorPrimaryText: '#FFFFFF',
  colorSecondary: '#C31D23',
  colorSecondaryText: '#FFFFFF',
  colorCardBase: '#ffffff',
  colorCardFont: '#0e0e0e',
  colorControlBase: '#252b66',
  colorPanel: '#263a60',
  colorPanelHeader: adjustColor('#263a60', 4),
  colorBackground: '#1e355b',
  colorControlBorder: rgba('#7289AF', .2),
  colorControlElement: '#7289AF',
  colorTextSecondary: '#7289AF',
  colorChipsPrimary: adjustColor('#C31D23', -8),
  colorChipsSecondary: adjustColor('#39598F', -8),
  patternBg: 'url(/assets/pattern.png) repeat',
};
LEAGUE_TOKENS[37] = {
  colorPrimary: '#E3BB80',
  colorPrimaryText: '#1C2134',
  colorSecondary: '#E3BB80',
  colorSecondaryText: '#1C2134',
  colorCardBase: '#2E375B',
  colorControlBase: '#414D7C',
  colorPanel: '#282e50',
  colorPanelHeader: adjustColor('#282e50', 8),
  colorBackground: '#1C2134',
  colorControlBorder: rgba('#9BA6D2', .2),
  colorControlElement: '#9BA6D2',
  colorTextSecondary: '#9BA6D2',
  colorChipsPrimary: adjustColor('#E3BB80', -8),
  colorChipsSecondary: adjustColor('#414D7C', -8),
};

LEAGUE_TOKENS[106] = {
  colorPrimary: '#ff8c16',
  colorPrimaryText: '#0071BC',
  colorSecondary: '#ff8c16',
  colorSecondaryText: '#1F3766',
  colorCardBase: '#3794D2',
  colorControlBase: '#1F3766',
  colorPanel: '#2979af',
  colorPanelHeader: '#3383b9',
  colorBackground: '#004979',
  colorControlBorder: rgba('#FFFFFF', 1),
  colorControlElement: '#a6b5d3',
  colorTextSecondary: '#FFFFFF',
  colorChipsPrimary: adjustColor('#ff8c16', -8),
  colorChipsSecondary: adjustColor('#414D7C', -8),
};
BETA_TOKENS[106] = {
  colorPrimary: '#ff8c16',
  colorPrimaryText: '#ffffff',
  colorSecondary: '#1f355d',
  colorSecondaryText: '#ffffff',
  colorCardBase: '#ffffff',
  colorCardFont: '#0e0e0e',
  colorControlBase: '#1F3766',
  colorPanel: '#244379',
  colorPanelHeader: '#38578d',
  colorBackground: '#1f355d',
  colorControlBorder: rgba('#6E85AE', 0.2),
  colorControlElement: '#6E85AE',
  colorTextSecondary: '#6E85AE',
  colorChipsPrimary: adjustColor('#ff8c16', -8),
  colorChipsSecondary: adjustColor('#20406e', -8),
  patternBg: 'url(/assets/pattern.png) repeat',
  patternCard: 'url(/assets/pattern.png) repeat',
};

export function applyTokens(document: Document, renderer: Renderer2, leagueId: number, ssr: boolean = true, beta: boolean = false) {
  const tokens = beta ? BETA_TOKENS[leagueId] || LEAGUE_TOKENS[leagueId] || {} : LEAGUE_TOKENS[leagueId] || {};
  if (tokens.colorPrimary) {
    setColorTokens(document, renderer, 'color-primary', tokens.colorPrimary, true, ssr);
    setColorTokens(document, renderer, 'color-primarybuttonfocus', rgba(tokens.colorPrimary, .4), false, ssr);
  }
  if (tokens.colorPrimaryText) {
    setColorTokens(document, renderer, 'color-primary-text', tokens.colorPrimaryText, false, ssr);
  }
  if (tokens.colorSecondary) {
    setColorTokens(document, renderer, 'color-secondary', tokens.colorSecondary, true, ssr);
  }
  if (tokens.colorSecondaryText) {
    setColorTokens(document, renderer, 'color-secondary-text', tokens.colorSecondaryText, false, ssr);
  }
  if (tokens.colorCardBase) {
    setColorTokens(document, renderer, 'color-cardbase', tokens.colorCardBase, true, ssr);
  }
  if (tokens.colorCardFont || tokens.colorFont) {
    setColorTokens(document, renderer, 'color-card-font', tokens.colorCardFont || tokens.colorFont, false, ssr);
  }
  if (tokens.colorCardFontMuted || tokens.colorTextSecondary) {
    setColorTokens(document, renderer, 'color-card-font-muted', tokens.colorCardFontMuted || tokens.colorTextSecondary, false, ssr);
  }
  if (tokens.colorControlBase) {
    setColorTokens(document, renderer, 'color-controlbase', tokens.colorControlBase, true, ssr);
    setColorTokens(document, renderer, 'color-secondarybuttonfocus', rgba(tokens.colorControlBase, .4), false, ssr);
  }
  if (tokens.colorControl) {
    setColorTokens(document, renderer, 'color-control', tokens.colorControl, true, ssr);
  }
  if (tokens.colorControlMuted) {
    setColorTokens(document, renderer, 'color-control-muted', tokens.colorControlMuted, false, ssr);
  }
  if (tokens.colorTextField) {
    setColorTokens(document, renderer, 'color-textfield', tokens.colorTextField, false, ssr);
  }
  if (tokens.colorPanel) {
    setColorTokens(document, renderer, 'color-panel', tokens.colorPanel, true, ssr);
  }
  if (tokens.colorPanelFont || tokens.colorFont) {
    setColorTokens(document, renderer, 'color-panel-font', tokens.colorPanelFont || tokens.colorFont, false, ssr);
  }
  if (tokens.colorPanelFontMuted || tokens.colorTextSecondary) {
    setColorTokens(document, renderer, 'color-panel-font-muted', tokens.colorPanelFontMuted || tokens.colorTextSecondary, false, ssr);
  }
  if (tokens.colorPanelHeader) {
    setColorTokens(document, renderer, 'color-panel-header', tokens.colorPanelHeader, false, ssr);
  }
  if (tokens.colorPanelHeaderText) {
    setColorTokens(document, renderer, 'color-panel-header-text', tokens.colorPanelHeaderText, false, ssr);
  }
  if (tokens.colorBackground) {
    setColorTokens(document, renderer, 'color-background', tokens.colorBackground, true, ssr);
  }
  if (tokens.colorControlBorder) {
    setColorTokens(document, renderer, 'color-controlborder', tokens.colorControlBorder, true, ssr);
  }
  if (tokens.colorControlElement) {
    setColorTokens(document, renderer, 'color-controlelement', tokens.colorControlElement, true, ssr);
  }
  if (tokens.colorTextSecondary) {
    setColorTokens(document, renderer, 'color-textsecondary', tokens.colorTextSecondary, false, ssr);
  }
  if (tokens.colorChipsPrimary) {
    setColorTokens(document, renderer, 'color-chipsprimary', tokens.colorChipsPrimary, true, ssr);
  }
  if (tokens.colorChipsSecondary) {
    setColorTokens(document, renderer, 'color-chipssecondary', tokens.colorChipsSecondary, true, ssr);
  }
  if (tokens.colorFont) {
    setColorTokens(document, renderer, 'color-colorfont', tokens.colorFont, false, ssr);
  }
  if (tokens.patternBg) {
    setColorTokens(document, renderer, 'pattern-bg', tokens.patternBg, false, ssr);
  }
  if (tokens.patternCard) {
    setColorTokens(document, renderer, 'pattern-card', tokens.patternBg, false, ssr);
  }
  if (tokens.patternPanel) {
    setColorTokens(document, renderer, 'pattern-panel', tokens.patternPanel, false, ssr);
  }
  if (tokens.patternControlBase) {
    setColorTokens(document, renderer, 'pattern-controlbase', tokens.patternControlBase, false, ssr);
  }
}

function setColorTokens(document: Document, renderer: Renderer2, tokenName: string, color: string, withStates = true, ssr: boolean = true): void {
  if (ssr) {
    renderer.setStyle(document.body, `--${tokenName}`, color);
  } else {
    document.body.style.setProperty(`--${tokenName}`, color);
  }
  if (withStates) {
    if (ssr) {
      renderer.setStyle(document.body, `--${tokenName}--hover`, adjustColor(color, -4));
      renderer.setStyle(document.body, `--${tokenName}--active`, adjustColor(color, -12));
      renderer.setStyle(document.body, `--${tokenName}--lighten`, adjustColor(color, 4));
    } else {
      document.body.style.setProperty(`--${tokenName}--hover`, adjustColor(color, -4));
      document.body.style.setProperty(`--${tokenName}--active`, adjustColor(color, -12));
      document.body.style.setProperty(`--${tokenName}--lighten`, adjustColor(color, 4));
    }
  }
}

function adjustColor(color: string, percent: number): string {
  const amount = Math.round((percent / 100) * 255);
  if (color.substr(0, 1) === '#') {
    const rgb = hexToRgb(color);
    return rgbToHex(rgb.r + amount, rgb.g + amount, rgb.b + amount);
  } else if (color.substr(0 , 5) === 'rgba(') {
    const rgba = color.replace(/[^\d,]/g, '').split(',');
    return `rgba(${+rgba[0] + amount}, ${+rgba[1] + amount}, ${+rgba[2] + amount}, ${rgba[3]})`;
  } else if (color.substr(0, 4) === 'rgb(') {
    const rgb = color.replace(/[^\d,]/g, '').split(',');
    return rgbToHex((+rgb[0]) + amount, (+rgb[1]) + amount, (+rgb[2]) + amount);
  }
}

function hexToRgb(hex: string): {r: number, g: number, b: number} {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

function componentToHex(c: number) {
  if (c > 255) {
    c = 255;
  }
  if (c < 0) {
    c = 0;
  }
  const hex = c.toString(16);
  return hex.length == 1 ? '0' + hex : hex;
}

function rgbToHex(r: number, g: number, b: number) {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

function rgba(color: string, opacity: number): string {
  const rgb = hexToRgb(color);
  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`;
}

