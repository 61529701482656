import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { forkJoin, Observable, of } from 'rxjs';
import { distinctUntilChanged, finalize, switchMap, take, takeUntil } from 'rxjs/operators';
import { Metrika } from 'ng-yandex-metrika';
import { componentDestroyed, FeedbackApi, FileApi, handleError, markFormGroupTouched, UntilDestroy } from '@mtgame/core';
import { ConfirmDialogComponent, DialogRef, DialogService, LoaderService, PHONE_MASK, ToastService } from '@mtgame/ui';
import { AuthService } from '@mtgame/auth';
import { LeagueRootService } from '@core/services/league-root.service';
import { LayoutService } from '../layout/layout.service';

@UntilDestroy
@Component({
  selector: 'mtg-feedback',
  templateUrl: './join.component.html',
  styleUrls: ['./join.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JoinComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  feedbackSent = false;
  isChanged = false;
  leagueId: number;
  phoneMask = PHONE_MASK;

  FEEDBACK_TYPES = ['Ошибка', 'Партнёрство', 'Комментарий'];

  constructor(
    private leagueRootService: LeagueRootService,
    private formBuilder: UntypedFormBuilder,
    private toasts: ToastService,
    private loaderService: LoaderService,
    private fileApi: FileApi,
    private feedbackApi: FeedbackApi,
    private dialogRef: DialogRef,
    private dialogService: DialogService,
    private metrika: Metrika,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    const league = this.leagueRootService.getLeagueModel();
    this.leagueId = league.id;
    this.form = this.formBuilder.group({
      fullName: new UntypedFormControl(null, [Validators.required]),
      phone: new UntypedFormControl(null, [Validators.required]),
      message: new UntypedFormControl(null),
      email: new UntypedFormControl({value: null, disabled: true}, [Validators.required, Validators.email]),
      captcha: new UntypedFormControl({value: null, disabled: true}, [Validators.required])
    });
    this.form.valueChanges.pipe(
      takeUntil(componentDestroyed(this)),
      distinctUntilChanged()
    ).subscribe(() => {
      this.isChanged = true;
    });
    this.dialogRef.addBeforeClose(this.canClose());
    this.authService.user$.pipe(take(1)).subscribe(user => {
      if (!user) {
        this.form.get('email').enable({ emitEvent: false });
        this.form.get('captcha').enable({ emitEvent: false });
      } else {
        this.form.patchValue({
          fullName: user.fullName,
          phone: user.phone
        }, { emitEvent: false });
      }
    });
  }

  ngOnDestroy(): void {
  }

  canClose(): Observable<boolean> {
    return new Observable(observer => {
      if (!this.isChanged || this.feedbackSent) {
        observer.next(true);
        observer.complete();
        return;
      }
      const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
        data: {
          message: 'Вы уверены что хотите закрыть страницу? Все изменения будут потеряны.'
        }
      });
      dialogRef.afterClosed().subscribe(res => {
        if (res) {
          observer.next(res);
        } else {
          observer.error(res);
        }
        observer.complete();
      });
    });
  }

  async sendFeedback(): Promise<void> {
    if (!this.form.valid) {
      markFormGroupTouched(this.form);
      if (this.form.get('captcha').enabled && !this.form.get('captcha').value) {
        this.toasts.danger('Пройдите проверку recaptcha');
      } else {
        this.toasts.danger('Заполните обязательные поля формы');
      }
      return Promise.resolve();
    }

    this.loaderService.show();
    const formData = this.form.value;
    try {
      if (formData.files && formData.files.length > 0) {
        formData.files = await Promise.resolve(formData.files.map(file => this.fileApi.upload(file)));
      } else {
        formData.files = [];
      }
    } catch (e) {
      handleError(e);
      this.toasts.danger('Ошибка загрузки файлов');
      this.loaderService.hide();
      return Promise.resolve();
    }

    try {
      formData.theme = 'Присоединиться к лиге';
      formData.message = `ФИО: ${formData.fullName}\nТелефон: ${formData.phone}\n\n${formData.message}`;
      await this.feedbackApi.sendLeagueFeedback(this.leagueId, formData);
      this.feedbackSent = true;
      this.metrika.fireEvent('feedbackSent');
      this.cdr.markForCheck();
    } catch (e) {
      handleError(e);
      if (e.error.error === 'captcha mismatch') {
        this.toasts.danger('Ошибка проверки recaptcha. Попробуйте еще раз');
      } else {
        handleError(e);
        this.toasts.danger('Не удалось отправить обратную связь. На севере ведуться работы, свяжитесь с нами позже');
      }
    } finally {
      this.loaderService.hide();
    }
  }

  close() {
    this.dialogRef.close();
  }
}
